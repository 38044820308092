<template>
  <div class="public Intelligent">
    <!--    <iframe class="pdfIframe" v-if="showPDF" :src="`http://localhost:8080/HomePdfDownload?riskBusId=${$route.query.riskBusId}`" frameborder="0" width="500px" height="500px"></iframe>-->
    <el-container>
      <!-- <el-aside width="220px" class="left-box">
        <el-menu :default-openeds="['1']">
          <el-menu-item index="1">
            <span slot="title">智能分析</span>
          </el-menu-item>
        </el-menu>
      </el-aside> -->
      <el-container>
        <el-main class="detail-box">
          <div class="detail-box-title">
            <div>
              <el-button
                  class="goback"
                  size="small"
                  type="text"
                  icon="el-icon-arrow-left"
                  @click="goBack()"
                  v-if="showReturn"
              >返回</el-button
              >
            </div>

            <el-button
                class="goback"
                size="small"
                type="text"
                icon="el-icon-printer"
                @click="toDownload()"
            >报告下载</el-button
            >
          </div>
          <h2 class="title">
            <span>经营者风险状况及商务信用评估报告</span>
          </h2>
          <div class="text-info">
            <p>
              {{ reportInfo }}
              <!--              {{-->
              <!--                `本报告由${this.strs}单用途预付消费卡协同监管平台出具，依据截止报告时间系统记录的信息生成。报告中的信息均由相关报数机构和信息主体提供，${this.strs}单用途预付消费卡协同监管平台不保证其真实性和准确性，但承诺在信息整合、汇总、展示的全过程中保持客观、中立的地位。 本报告仅向预付卡监管部门工作人员提供，不得作为金融机构的授信依据，请妥善保管。因保管不当造成信息泄露的，${this.strs}单用途预付消费卡协同监管平台不承担相关责任。`-->
              <!--              }}-->
              <!--              {{-->
              <!--                `本报告由${this.strs}单用途预付消费卡协同监管服务平台出具，依据报告期内系统获得的的信息数据生成。报告中的信息数据均由政府大数据管理机构、经营主体及第三方合作机构提供。${this.strs}单用途预付消费卡协同监管服务平台承诺在信息数据采集、汇总、分析、展示等全过程中保持客观、中立立场。 本报告仅向预付消费卡监管部门工作人员提供，须妥善保管。因保管不当造成信息数据泄露的，应承担相关行政或法律责任。`-->
              <!--              }}-->
            </p>
          </div>

          <div class="conbox">
            <div class="sub-conbox">
              <h3 class="sub-title">
                <i class="icon-tit1"></i>
                <span>风险及信用状况概览</span>
              </h3>
              <!-- <h4 class="item-title"><span>税务欠费信息</span></h4> -->
              <table
                  class="table"
                  style="margin-bottom: 15px; margin-top: 40px"
              >
                <tr>
                  <th :style="{ textAlign: 'center' }">综合评分</th>
                  <th :style="{ textAlign: 'center' }">风险等级</th>
                  <th :style="{ textAlign: 'center' }">预付码</th>
                  <th :style="{ textAlign: 'center' }">经营状况</th>
                  <th :style="{ textAlign: 'center' }">信用等级</th>
                </tr>
                <tr>
                  <td
                      :style="{ textAlign: 'center', padding: '0 20px 0 20px' }"
                  >
                    {{ newoperatorInfo.rankScore || "0" }}
                  </td>
                  <td
                      :style="{ textAlign: 'center', padding: '0 20px 0 20px' }"
                  >
                    {{ operatorInfo.risk }}
                  </td>
                  <td
                      :style="{ textAlign: 'center', padding: '0 20px 0 20px' }"
                  >
                    <img
                        :src="baseUrl + operatorInfo.safeCodeImg"
                        :style="{ width: '30px', height: '30px' }"
                    />
                    {{
                      newoperatorInfo.safeCodeColor == "1"
                          ? "灰码"
                          : newoperatorInfo.safeCodeColor == "2"
                          ? "红码"
                          : newoperatorInfo.safeCodeColor == "3"
                              ? "黄码"
                              : newoperatorInfo.safeCodeColor == "4"
                                  ? "绿码"
                                  : "黑码"
                    }}
                  </td>
                  <td
                      :style="{ textAlign: 'center', padding: '0 20px 0 20px' }"
                  >
                    {{ newoperatorInfo.busState || "--" }}
                  </td>
                  <td
                      :style="{ textAlign: 'center', padding: '0 20px 0 20px' }"
                  >
                    {{ newoperatorInfo.creditLevel || "--" }}
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="conbox">
            <h3 class="sub-title">
              <i class="icon-tit1"></i>
              <span>基本信息</span>
            </h3>
            <div class="sub-conbox">
              <h4 class="item-title"><span>工商信息</span></h4>
              <table class="table">
                <tr>
                  <th>经营者名称</th>
                  <td>{{ basicInfo.authority }}</td>
                  <th>统一社会信用代码</th>
                  <td>{{ basicInfo.creditNo }}</td>
                </tr>
                <tr>
                  <th>类型</th>
                  <td>{{ basicInfo.companyType }}</td>
                  <th>法定代表人</th>
                  <td>{{ basicInfo.legalPerson }}</td>
                </tr>
                <tr>
                  <th>注册资本</th>
                  <td>{{ basicInfo.capital }}</td>
                  <th>成立日期</th>
                  <td>{{ basicInfo.establishDate || "--" }}</td>
                </tr>
                <tr>
                  <th>营业期限自</th>
                  <td>{{ basicInfo.operationStartDate || "--" }}</td>
                  <th>营业期限至</th>
                  <td>{{ basicInfo.operationEndDate || "--" }}</td>
                </tr>
                <tr>
                  <th>登记状态</th>
                  <td>{{ basicInfo.companyStatus }}</td>
                  <th>住所</th>
                  <td>{{ basicInfo.companyAddress }}</td>
                </tr>
                <tr>
                  <th>经营范围</th>
                  <td colspan="3">{{ basicInfo.businessScope }}</td>
                </tr>
              </table>
            </div>
            <div class="sub-conbox">
              <h4 class="item-title"><span>出资人信息</span></h4>
              <!-- <h4 class="item-title"><span>主要出资人信息</span></h4> -->
              <table class="table">
                <tr v-for="(item, i) in shareholderInfodata" :key="i">
                  <th>股东</th>
                  <td>{{ item.entName || "--" }}</td>
                  <th>出资比例</th>
                  <td>{{ item.conProp || "--" }}{{item.conProp ? '%' : ''}}</td>
                  <th>出资金额</th>
                  <td>{{ item.conAm || "--"}}{{item.conProp ? '万元' : ''}}</td>
                </tr>
                <!-- <tr>
                  <th>股东</th>
                  <td>--</td>
                  <th>出资比例</th>
                  <td>--</td>
                  <th>出资金额</th>
                  <td>--</td>
                </tr>
                <tr>
                  <th>股东</th>
                  <td>--</td>
                  <th>出资比例</th>
                  <td>--</td>
                  <th>出资金额</th>
                  <td>--</td>
                </tr> -->
              </table>
            </div>
          </div>
          <div class="conbox">
            <h3 class="sub-title">
              <i class="icon-tit2"></i>
              <span>公共信息</span>
            </h3>
            <div class="text-info">
              <p v-html="publicInfo"></p>
            </div>
            <div class="sub-conbox" v-if="interdata[0] == 1">
              <h4 class="item-title"><span>电力欠费信息</span></h4>
              <table class="table" v-if="publicOweInfo.electrics != ''">
                <tr v-for="(item, i) in publicOweInfo.electrics" :key="i">
                  <th>用电年月</th>
                  <td>{{ item.electricTime }}</td>
                  <th>欠费金额（元）</th>
                  <td>{{ item.electricMoney }}</td>
                </tr>
              </table>
              <!-- <div v-else>暂无数据</div> -->
              <table class="table" v-else>
                <tr v-for="(item, i) in defaulDtata" :key="i">
                  <th>用电年月</th>
                  <td>{{ item.electricTime }}</td>
                  <th>欠费金额（元）</th>
                  <td>{{ item.electricMoney }}</td>
                </tr>
              </table>
            </div>
            <div class="sub-conbox" v-if="interdata[1] == 2">
              <h4 class="item-title"><span>水务欠费信息</span></h4>
              <table class="table" v-if="publicOweInfo.waters != ''">
                <tr v-for="(item, i) in publicOweInfo.water" :key="i">
                  <th>用水年月</th>
                  <td>{{ item.electricTime || "--" }}</td>
                  <th>欠费金额（元）</th>
                  <td>{{ item.electricMoney || "0.00" }}</td>
                </tr>
              </table>
              <table class="table" v-else>
                <tr v-for="(item, i) in defaulDtata" :key="i">
                  <th>用水年月</th>
                  <td>{{ item.electricTime || "--" }}</td>
                  <th>欠费金额（元）</th>
                  <td>{{ item.electricMoney || "0.00" }}</td>
                </tr>
              </table>
              <!-- <table class="table">
                <tr>
                  <th>用水年月</th>
                  <td>{{ publicOweInfo.waterTime }}</td>
                  <th>欠费金额</th>
                  <td>{{ publicOweInfo.waterMoney }}</td>
                </tr>
              </table> -->
            </div>
            <div class="sub-conbox" v-if="interdata[2] == 3">
              <h4 class="item-title"><span>燃气欠费信息</span></h4>
              <table class="table" v-if="publicOweInfo.gas != ''">
                <tr v-for="(item, i) in publicOweInfo.gas" :key="i">
                  <th>用气年月</th>
                  <td>{{ item.gasTime || "--" }}</td>
                  <th>欠费金额（元）</th>
                  <td>{{ item.gasMoney || "0.00" }}</td>
                </tr>
              </table>
              <!-- <div v-else>暂无数据</div> -->
              <table class="table" v-else>
                <tr v-for="(item, i) in defaulDtata" :key="i">
                  <th>用气年月</th>
                  <td>{{ item.gasTime || "--" }}</td>
                  <th>欠费金额（元）</th>
                  <td>{{ item.gasMoney || "0.00" }}</td>
                </tr>
              </table>
            </div>
            <div class="sub-conbox" v-if="interdata[3] == 4">
              <h4 class="item-title"><span>住房公积金缴存信息</span></h4>
              <div v-if="publicOweInfo.accumulationFundVO != ''">
                <table class="table" style="margin-bottom: 15px">
                  <!-- <tr>
                  <th>单位缴交状态</th>
                  <td>--</td>
                  <th>末次汇缴月份</th>
                  <td>{{ publicOweInfo.payTime }}</td>
                </tr>
                <tr>
                  <th>当前实缴人数</th>
                  <td>{{ publicOweInfo.payNum }}</td>
                  <th>月交金额</th>
                  <td>{{ publicOweInfo.payMoney }}</td>
                </tr> -->
                  <tr
                      v-for="(item, i) in publicOweInfo.accumulationFundVO"
                      :key="i"
                  >
                    <th>缴纳年月</th>
                    <td>{{ item.payTime || "--" }}</td>
                    <th>缴纳人数（人）</th>
                    <td>{{ item.payNum || "0" }}</td>
                    <th>缴纳金额（元）</th>
                    <td>{{ item.payMoney || "0.00" }}</td>
                  </tr>
                </table>
              </div>
              <div v-else>
                <table class="table" style="margin-bottom: 15px">
                  <tr v-for="(item, i) in defaulDtata" :key="i">
                    <th>缴纳年月</th>
                    <td>{{ item.payTime || "--" }}</td>
                    <th>缴纳人数（人）</th>
                    <td>{{ item.payNum || "--" }}</td>
                    <th>缴纳金额（元）</th>
                    <td>{{ item.payMoney || "0.00" }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="sub-conbox" v-if="interdata[4] == 5">
              <h4 class="item-title"><span>公积金个贷逾期信息</span></h4>
              <div v-if="publicOweInfo.overdues != ''">
                <table class="table" style="margin-bottom: 15px">
                  <tr v-for="(item, i) in publicOweInfo.overdues" :key="i">
                    <th>逾期年月</th>
                    <td>{{ item.loanDate || "--" }}</td>
                    <th>逾期金额（元）</th>
                    <td>{{ item.pastAmount || "0.00" }}</td>
                  </tr>
                  <!-- <tr>
                    <th>贷款年限</th>
                    <td>{{ item.loanTerm }}</td>
                    <th>贷款余额</th>
                    <td>{{ item.loanBalance }}</td>
                  </tr>
                  <tr>
                    <th>贷款总额</th>
                    <td>{{ item.loanTotal }}</td>
                    <th>贷款日期</th>
                    <td>{{ item.loanDate }}</td>
                  </tr> -->
                </table>
              </div>
              <div v-else>
                <table class="table" style="margin-bottom: 15px">
                  <tr v-for="(item, i) in defaulDtata" :key="i">
                    <th>逾期年月</th>
                    <td>{{ item.loanDate || "--" }}</td>
                    <th>逾期金额（元）</th>
                    <td>{{ item.pastAmount || "0.00" }}</td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="sub-conbox" v-if="interdata[5] == 6">
              <h4 class="item-title"><span>商业贷款逾期信息</span></h4>
              <!-- <div v-if="publicOweInfo.shebaoInfoVO != ''">
                <table
                  class="table"
                  style="margin-bottom: 15px"
                >
                  <tr  v-for="(item, i) in publicOweInfo.shebaoInfoVO"
                  :key="i">
                    <th>缴纳年月</th>
                    <td>{{ item.payTime || "--" }}</td>
                    <th>缴纳人数（人）</th>
                    <td>{{ item.payNum || "--" }}</td>
                  </tr>
                </table>
              </div> -->
              <div>
                <table class="table" style="margin-bottom: 15px">
                  <tr v-for="(item, i) in defaulDtata" :key="i">
                    <th>逾期年月</th>
                    <td>{{ item.loanDate || "--" }}</td>
                    <th>逾期金额（元）</th>
                    <td>{{ item.pastAmount || "0.00" }}</td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="sub-conbox" v-if="interdata[6] == 7">
              <h4 class="item-title"><span>社保缴纳信息</span></h4>
              <div v-if="publicOweInfo.shebaoInfoVO != ''">
                <table class="table" style="margin-bottom: 15px">
                  <tr v-for="(item, i) in publicOweInfo.shebaoInfoVO" :key="i">
                    <th>缴纳年月</th>
                    <td>{{ item.payTime || "--" }}</td>
                    <th>缴纳人数（人）</th>
                    <td>{{ item.payNum || "--" }}</td>
                  </tr>
                </table>
              </div>
              <div v-else>
                <table class="table" style="margin-bottom: 15px">
                  <tr v-for="(item, i) in defaulDtata" :key="i">
                    <th>缴纳年月</th>
                    <td>{{ item.payTime || "--" }}</td>
                    <th>缴纳人数（人）</th>
                    <td>{{ item.payNum || "--" }}</td>
                  </tr>
                </table>
              </div>
            </div>

            <div class="sub-conbox" v-if="interdata[7] == 8">
              <h4 class="item-title"><span>市场监管局12315投诉信息</span></h4>
              <table class="table" style="margin-bottom: 15px">
                <tr>
                  <th :style="{ textAlign: 'center' }">被投诉时间</th>
                  <th :style="{ textAlign: 'center' }">被投诉内容</th>
                  <th :style="{ textAlign: 'center' }">答复部门</th>
                  <th :style="{ textAlign: 'center' }">答复内容</th>
                  <th :style="{ textAlign: 'center' }">状态</th>
                </tr>
                <tr>
                  <td
                      :style="{ textAlign: 'center', padding: '0 20px 0 20px' }"
                  >
                    --
                  </td>
                  <td
                      :style="{ textAlign: 'center', padding: '0 20px 0 20px' }"
                  >
                    --
                  </td>
                  <td
                      :style="{ textAlign: 'center', padding: '0 20px 0 20px' }"
                  >
                    --
                  </td>
                  <td
                      :style="{ textAlign: 'center', padding: '0 20px 0 20px' }"
                  >
                    --
                  </td>
                  <td
                      :style="{ textAlign: 'center', padding: '0 20px 0 20px' }"
                  >
                    --
                  </td>
                </tr>
              </table>
            </div>

            <div class="sub-conbox" v-if="interdata[8] == 9">
              <h4 class="item-title">
                <span>违规违法处罚及失信被执行情况</span>
              </h4>
              <table class="table" style="margin-bottom: 15px">
                <tr>
                  <th :style="{ textAlign: 'center' }">序号</th>
                  <th :style="{ textAlign: 'center' }">案号</th>
                  <th :style="{ textAlign: 'center' }">执行法院</th>
                  <th :style="{ textAlign: 'center' }">执行依据文号</th>
                  <th :style="{ textAlign: 'center' }">履行情况</th>
                  <th :style="{ textAlign: 'center' }">立案日期</th>
                  <th :style="{ textAlign: 'center' }">发布日期</th>
                </tr>
                <tr>
                  <td
                      :style="{ textAlign: 'center', padding: '0 20px 0 20px' }"
                  >
                    --
                  </td>
                  <td
                      :style="{ textAlign: 'center', padding: '0 20px 0 20px' }"
                  >
                    --
                  </td>
                  <td
                      :style="{ textAlign: 'center', padding: '0 20px 0 20px' }"
                  >
                    --
                  </td>
                  <td
                      :style="{ textAlign: 'center', padding: '0 20px 0 20px' }"
                  >
                    --
                  </td>
                  <td
                      :style="{ textAlign: 'center', padding: '0 20px 0 20px' }"
                  >
                    --
                  </td>
                  <td
                      :style="{ textAlign: 'center', padding: '0 20px 0 20px' }"
                  >
                    --
                  </td>
                  <td
                      :style="{ textAlign: 'center', padding: '0 20px 0 20px' }"
                  >
                    --
                  </td>
                </tr>
              </table>
            </div>

            <div class="sub-conbox" v-if="interdata[9] == 10">
              <h4 class="item-title"><span>税务欠费信息</span></h4>
              <table class="table" style="margin-bottom: 15px">
                <tr>
                  <th :style="{ textAlign: 'center' }">序号</th>
                  <th :style="{ textAlign: 'center' }">主管税务机关</th>
                  <th :style="{ textAlign: 'center' }">欠费总额</th>
                  <th :style="{ textAlign: 'center' }">欠税统计日期</th>
                </tr>
                <tr>
                  <td
                      :style="{ textAlign: 'center', padding: '0 20px 0 20px' }"
                  >
                    --
                  </td>
                  <td
                      :style="{ textAlign: 'center', padding: '0 20px 0 20px' }"
                  >
                    --
                  </td>
                  <td
                      :style="{ textAlign: 'center', padding: '0 20px 0 20px' }"
                  >
                    --
                  </td>
                  <td
                      :style="{ textAlign: 'center', padding: '0 20px 0 20px' }"
                  >
                    --
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="conbox">
            <h3 class="sub-title">
              <i class="icon-tit3"></i>
              <span>经营情况</span>
            </h3>
            <div class="text-info">
              <p v-html="manageInfo">
                {{
                  manageInfo
                }}
              </p>
            </div>
            <div class="sub-conbox">
              <h4 class="item-title"><span>经营者信息</span></h4>
              <table class="table code-table">
                <tr>
                  <td rowspan="6" class="code-img-box">
                    <h5>预付码状态</h5>
                    <div class="code-img">
                      <img :src="baseUrl + operatorInfo.safeCodeImg" />
                      <span :class="`color${newoperatorInfo.safeCodeColor}`">{{
                          operatorInfo.safeCode || "0"
                        }}</span>
                    </div>
                    <p>
                      数据更新至<br />{{ operatorInfo.safeCodeTime || "0" }}
                    </p>
                  </td>
                  <th>经营者名称</th>
                  <td>{{ operatorInfo.busName || "0" }}</td>
                  <th>店铺数量</th>
                  <td>{{ operatorInfo.busNum || "0" }} 家</td>
                </tr>
                <tr>
                  <th>员工人数</th>
                  <td>{{ operatorInfo.people || "0" }} 人</td>
                  <th>所在地区</th>
                  <td>{{ operatorInfo.address || "0" }}</td>
                </tr>
                <tr>
                  <th>所属行业</th>
                  <td>{{ operatorInfo.industry }}</td>
                  <th>类型</th>
                  <td>{{ operatorInfo.type || "0" }}</td>
                </tr>
                <tr>
                  <th>资金管理方式</th>

                  <td>
                    {{
                      newoperatorInfo.safeCodeColor == "1"
                          ? "无"
                          : operatorInfo.fundMode || "0"
                    }}
                  </td>
                  <th>风险状况</th>
                  <td>{{ operatorInfo.risk || "0" }}</td>
                </tr>
                <tr>
                  <th>会员数</th>
                  <td>{{ operatorInfo.vipNum || "0" }} 人</td>
                  <th>风险处置</th>
                  <td>{{ operatorInfo.damaged || "0" }}</td>
                </tr>
                <tr>
                  <th>风险处置结果</th>
                  <td colspan="3">
                    {{ operatorInfo.damagedResult || "0" }}
                  </td>
                </tr>
              </table>
            </div>
            <div class="sub-conbox">
              <h4 class="item-title"><span>预付交易状况</span></h4>
              <div class="number-box">
                <div class="number-item">
                  <p>交易笔数（笔）</p>
                  <div class="box bg1">
                    <i></i><i></i>
                    <p>{{ transactionsInfo.cardNum || "0" }}</p>
                  </div>
                </div>
                <div class="number-item">
                  <p>交易金额（元）</p>
                  <div class="box bg1">
                    <i></i><i></i>
                    <p>{{ transactionsInfo.cardMoney || "0" }}</p>
                  </div>
                </div>
                <div class="number-item">
                  <p>兑付金额（元）</p>
                  <div class="box bg1">
                    <i></i><i></i>
                    <p>{{ transactionsInfo.cashingMoney || "0" }}</p>
                  </div>
                </div>
                <div class="number-item">
                  <p>兑付比（%）</p>
                  <div class="box bg1">
                    <i></i><i></i>
                    <p>{{ transactionsInfo.cashingRatio || "0" }}</p>
                  </div>
                </div>
              </div>
              <div class="echarts-but">
                <el-radio-group v-model="radio5">
                  <el-radio-button
                      v-for="item in radioData"
                      :label="item.value"
                      :key="item.value"
                  >{{ item.name }}</el-radio-button
                  >
                </el-radio-group>
              </div>
              <div class="charts">
                <LineChart
                    :chartData="newlineChartData"
                    :legendData="['交易笔数', '交易金额', '兑付金额', '兑付比']"
                    :units="['笔', '元', '元', '%']"
                    :yAxis="['', '%']"
                    :yAxisIndex="['0', '0', '0', '1']"
                ></LineChart>
              </div>
            </div>
            <div class="sub-conbox">
              <h4 class="item-title"><span>预收资金监管情况</span></h4>
              <div class="number-box">
                <div class="number-item">
                  <p>应存管发卡金额（元）</p>
                  <div class="box bg2">
                    <i></i><i></i>
                    <p>{{ moneyInfo.tranMoney | numFilter }}</p>
                  </div>
                </div>
                <div class="number-item">
                  <p>存管金额（元）</p>
                  <div class="box bg2">
                    <i></i><i></i>
                    <p>{{ moneyInfo.cgMoney | numFilter }}</p>
                  </div>
                </div>
              </div>
              <div class="echarts-but">
                <el-radio-group v-model="radio2">
                  <el-radio-button
                      v-for="item in radioData"
                      :label="item.value"
                      :key="item.value"
                  >{{ item.name }}</el-radio-button
                  >
                </el-radio-group>
              </div>
              <div class="charts">
                <LineChart
                    :chartData="lineChartData2"
                    :legendData="['应存管发卡金额', '存管资金']"
                    :units="['元', '元']"
                    :yAxis="['']"
                    :yAxisIndex="['0']"
                ></LineChart>
              </div>
              <div class="number-box">
                <div class="number-item">
                  <p>应保险发卡金额（元）</p>
                  <div class="box bg1">
                    <i></i><i></i>
                    <p>{{ insuredInfo.money || "0" }}</p>
                  </div>
                </div>
                <div class="number-item">
                  <p>保额（元）</p>
                  <div class="box bg1">
                    <i></i><i></i>
                    <p>{{ insuredInfo.beMoney || "0" }}</p>
                  </div>
                </div>
                <div class="number-item">
                  <p>担保或保函金额（元）</p>
                  <div class="box bg1">
                    <i></i><i></i>
                    <p>0.00</p>
                  </div>
                </div>
              </div>
              <div class="echarts-but">
                <el-radio-group v-model="radio3">
                  <el-radio-button
                      v-for="item in radioData"
                      :label="item.value"
                      :key="item.value"
                  >{{ item.name }}</el-radio-button
                  >
                </el-radio-group>
              </div>
              <div class="charts">
                <LineChart
                    :chartData="lineChartData3"
                    :legendData="['应保险发卡金额', '保额']"
                    :units="['元', '元']"
                    :yAxis="['']"
                    :yAxisIndex="['0']"
                ></LineChart>
              </div>
            </div>
            <div class="sub-conbox">
              <h4 class="item-title"><span>投诉受理状况</span></h4>
              <div class="number-box">
                <div class="number-item">
                  <p>被投诉（次）</p>
                  <div class="box bg1">
                    <i></i><i></i>
                    <p>{{ complainInfo.complaintNum || "0" }}</p>
                  </div>
                  <div class="right">
                    <p>已受理：{{ complainInfo.dealNum || "0" }}</p>
                    <p>待受理：{{ complainInfo.umDealNum || "0" }}</p>
                  </div>
                </div>
                <div class="number-item">
                  <p>被处罚（次）</p>
                  <div class="box bg1">
                    <i></i><i></i>
                    <p>{{ complainInfo.punishNum || "0" }}</p>
                  </div>
                  <div class="right">
                    <p>黑名单：{{ complainInfo.blackNum || "0" }}</p>
                    <p>中止发卡：{{ complainInfo.stopNum || "0" }}</p>
                  </div>
                </div>
              </div>
              <div class="echarts-but">
                <el-radio-group v-model="radio4">
                  <el-radio-button
                      v-for="item in radioData"
                      :label="item.value"
                      :key="item.value"
                  >{{ item.name }}</el-radio-button
                  >
                </el-radio-group>
              </div>
              <div class="charts">
                <LineChartnew
                    :chartData="lineChartData4"
                    :legendData="['投诉']"
                    :units="['']"
                    :yAxis="['']"
                    :yAxisIndex="['0']"
                ></LineChartnew>
                <!-- <BarChart :chartData="barChartData" :legendData="['投诉', '处罚']"></BarChart> -->
              </div>
            </div>
            <div class="sub-conbox">
              <h4 class="item-title"><span>风险处置情况</span></h4>
              <div class="number-box">
                <div class="number-item">
                  <p>处置日期</p>
                  <div class="box bg1">
                    <i></i><i></i>
                    <p>{{ damagedInfo.time || "0" }}</p>
                  </div>
                </div>
                <div class="number-item">
                  <p>涉及人数（人）</p>
                  <div class="box bg1">
                    <i></i><i></i>
                    <p>{{ damagedInfo.num || "0" }}</p>
                  </div>
                </div>
                <div class="number-item">
                  <p>涉及金额（元）</p>
                  <div class="box bg1">
                    <i></i><i></i>
                    <p>{{ damagedInfo.money || "0" }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div class="sub-conbox">
              <h4 class="item-title"><span>预付合同签约情况</span></h4>
              <div class="idiot-one">
                <div class="box" v-for="item in forCodeData" :key="item.type">
                  <div class="box-left">
                    <div
                        class="box-leftchild"
                        v-for="(itemval, ind) in item.seriesData"
                        :key="ind"
                    >
                      {{ itemval.name }}
                      <div>{{ itemval.value }}</div>
                      {{ itemval.valcom }}
                    </div>
                  </div>
                  <div class="box-right">
                    <Pieadvance
                        :chartData="{
                        seriesData: item.seriesData,
                      }"
                    ></Pieadvance>
                  </div>
                </div>
              </div>
            </div>

            <div class="sub-conbox">
              <h4 class="item-title"><span>预付消费结构</span></h4>
              <div class="idiot-one">
                <div
                    class="box"
                    v-for="item in forCodeDatatwo"
                    :key="item.type"
                >
                  <div class="fl box-left">
                    <div class="box-left-childtop">
                      {{ item.type == 1 ? "用户人群：" : "交易金额：" }}
                      <div class="box-left-childtopval">
                        {{ item.xfrq }}
                        <span class="box-left-childtopvalcom">{{
                            item.xfrqcom
                          }}</span>
                      </div>
                    </div>

                    <div class="box-left-childbottom">
                      <div
                          class="box-left-childbottomval"
                          v-for="(itemval, index) in item.seriesData"
                          :key="itemval.val"
                      >
                        <div
                            class="box-left-childbottomvalicon"
                            :style="{ background: forCodeDatacolor[index] }"
                        ></div>
                        <div>{{ itemval.name }}</div>
                        <div
                            class="box-left-childbottomvalpercentage"
                            :style="{ color: forCodeDatacolor[index] }"
                        >
                          {{ itemval.value }}%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="fr box-right">
                    <div class="box-url"></div>
                    <PieEpide
                        :chartData="{
                        seriesData: item.seriesData,
                        Gender: item.Gender,
                      }"
                        width="260px"
                        height="260px"
                    >
                    </PieEpide>
                  </div>
                </div>
              </div>
            </div>

            <div class="sub-conbox">
              <h4 class="item-title">
                <span
                >{{
                    this.tabPosition2 == 1
                        ? "门店防疫"
                        : this.tabPosition2 == 2
                        ? "从业人员"
                        : "消费人员"
                  }}情况</span
                >
              </h4>
              <el-radio-group v-model="tabPosition2" class="tab-select-btn">
                <el-radio-button label="1">门店防疫</el-radio-button>
                <el-radio-button label="2">从业人员</el-radio-button>
                <el-radio-button label="3">消费人员</el-radio-button>
              </el-radio-group>
              <div class="echarts-but">
                <el-radio-group v-model="radio1">
                  <el-radio-button
                      v-for="item in radioData1"
                      :label="item.value"
                      :key="item.value"
                  >{{ item.name }}</el-radio-button
                  >
                </el-radio-group>
              </div>
              <div class="charts">
                <BarChart
                    :chartData="barChartData"
                    :legendData="['健康从业人员', '有症状从业人员']"
                ></BarChart>
              </div>
            </div>

            <div class="conbox">
              <div class="sub-conbox">
                <h3 class="sub-title">
                  <i class="icon-tit1"></i>
                  <span>结论及风险提示</span>
                </h3>
                <div class="getOverDesdatabox">
                  <div
                      class="getOverDesdatachild"
                      v-for="(item, ind) in getOverDesdata"
                      :key="ind"
                  >
                    ☆ {{ item }}
                  </div>
                </div>
              </div>
            </div>

            <!-- <div class="sub-conbox">
              <h4 class="item-title">
                <span>结论及风险提示</span>
              </h4>

            </div> -->
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script type="text/javascript">
import axios from "axios";
import { baseURL } from "@/api/config";
import LineChart from "@/components/charts/line2"; // 四码折线图
import LineChartnew from "@/components/charts/line3"; // 四码折线图
import BarChart from "@/components/charts/bar";
import Pieadvance from "@/components/charts/Pieadvance.vue";
import PieEpide from "@/components/charts/pieHomeEpide";
import {
  basicInfo,
  publicInfo,
  publicOweInfo,
  manageInfo,
  operatorInfo,
  transactionsInfo,
  moneyInfo,
  insuredInfo,
  complainInfo,
  damagedInfo,
  contractStatistics,
  consumerStatistics,
  epidemicPreventionCumulative,
  getSafeCodeColor,
  getOverDes,
  shareholderInfo,
} from "@/api/intelligent";
import $api from "../api/api";

export default {
  name: "IntelligentAnalysis",
  data() {
    return {
      date:
          new Date().getFullYear() +
          "年" +
          (new Date().getMonth() + 1) +
          "月" +
          new Date().getDate() +
          "日",
      showPDF: false,
      baseUrl: baseURL,
      radio5: "1",
      radio1: "1",
      radio2: "1",
      radio3: "1",
      radio4: "1",
      tabPosition2: 1,

      interdata: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      forCodeDatacolor: ["#125fe0", "#208dfd", "#18bbff", "#00ff5a", "#c6ff00"],
      defaulDtata: [
        {
          electricTime: "--",
          electricMoney: "0.00",
          gasTime: "--",
          gasMoney: "0.00",
          payTime: "--",
          payMoney: "0.00",
          loanDate: "--",
          pastAmount: "0.00",
          payNum: "0",
        },
        {
          electricTime: "--",
          electricMoney: "0.00",
          gasTime: "--",
          gasMoney: "0.00",
          payTime: "--",
          payMoney: "0.00",
          loanDate: "--",
          pastAmount: "0.00",
          payNum: "0",
        },
        {
          electricTime: "--",
          electricMoney: "0.00",
          gasTime: "--",
          gasMoney: "0.00",
          payTime: "--",
          payMoney: "0.00",
          loanDate: "--",
          pastAmount: "0.00",
          payNum: "0",
        },
        {
          electricTime: "--",
          electricMoney: "0.00",
          gasTime: "--",
          gasMoney: "0.00",
          payTime: "--",
          payMoney: "0.00",
          loanDate: "--",
          pastAmount: "0.00",
          payNum: "0",
        },
        {
          electricTime: "--",
          electricMoney: "0.00",
          gasTime: "--",
          gasMoney: "0.00",
          payTime: "--",
          payMoney: "0.00",
          loanDate: "--",
          pastAmount: "0.00",
          payNum: "0",
        },
        {
          electricTime: "--",
          electricMoney: "0.00",
          gasTime: "--",
          gasMoney: "0.00",
          payTime: "--",
          payMoney: "0.00",
          loanDate: "--",
          pastAmount: "0.00",
          payNum: "0",
        },
      ],
      forCodeData: [
        {
          seriesData: [
            {
              name: "已签约",
              value: 0,
              valcom: "份",
            },
            {
              name: "未签约",
              value: 0,
              valcom: "份",
            },
          ],
        },
        {
          seriesData: [
            {
              name: "履约",
              value: 0,
              valcom: "份",
            },
            {
              name: "违约",
              value: 0,
              valcom: "份",
            },
          ],
        },
      ],

      forCodeDatatwo: [
        {
          seriesData: [
            {
              name: "儿童",
              value: 0,
            },
            {
              name: "少年",
              value: 0,
            },
            {
              name: "青年",
              value: 0,
            },
            {
              name: "中年",
              value: 0,
            },
            {
              name: "老年",
              value: 0,
            },
          ],
          xfrq: 0,
          xfrqcom: "人",
          man: 0,
          woman: 0,
          type: 1,
        },
        {
          seriesData: [
            {
              name: "儿童",
              value: 0,
            },
            {
              name: "少年",
              value: 0,
            },
            {
              name: "青年",
              value: 0,
            },
            {
              name: "中年",
              value: 0,
            },
            {
              name: "老年",
              value: 0,
            },
          ],
          xfrq: 0,
          xfrqcom: "元",
          man: 0,
          woman: 0,
          type: 2,
        },
      ],
      radioData: [
        {
          name: "近一周",
          value: "1",
        },
        {
          name: "近一月",
          value: "2",
        },
        {
          name: "近半年",
          value: "3",
        },
        {
          name: "全部",
          value: "4",
        },
      ],
      newlineChartData: {
        xAxisData: [],
        seriesData: [
          {
            name: "交易笔数",
            data: [],
          },
          {
            name: "交易金额",
            data: [],
          },
          {
            name: "兑付金额",
            data: [],
          },
          {
            name: "兑付比",
            data: [],
          },
        ],
      },
      lineChartData2: {
        xAxisData: [],
        seriesData: [
          {
            name: "应存管发卡金额",
            data: [],
          },
          {
            name: "存管资金",
            data: [],
          },
        ],
      },
      lineChartData3: {
        xAxisData: [],
        seriesData: [
          {
            name: "应保险发卡金额",
            data: [],
          },
          {
            name: "保额",
            data: [],
          },
        ],
      },
      lineChartData4: {
        xAxisData: [],
        seriesData: [
          {
            name: "",
            data: "",
          },
        ],
      },
      lineChartData4Xdata: [],
      lineChartData4SeriesData: [],
      barChartData: {
        xAxisData: [2014, 2015, 2016, 2017, 2018, 2019],
        seriesData: [
          {
            name: "有症状从业人员",
            data: [1, 2, 3, 4, 5, 6],
          },
          {
            name: "有症状从业人员",
            data: [2, 3, 4, 5, 6, 7],
          },
        ],
      },
      radioData1: [
        {
          name: "近一周",
          value: "1",
        },
        {
          name: "近一月",
          value: "2",
        },
        {
          name: "近半年",
          value: "3",
        },
        {
          name: "全部",
          value: "4",
        },
      ],
      basicInfo: {
        authority: "",
        creditNo: "",
        companyType: "",
        legalPerson: "",
        capital: "",
        establishDate: "",
        operationEndDate: "",
        operationStartDate: "",
        companyStatus: "",
        companyAddress: "",
        businessScope: "",
      },
      publicInfo: "",
      publicOweInfo: {
        overdues: "",
        payMoney: "",
        payNum: "",
        payTime: "",
        gas: "",
        waterMoney: "",
        waterTime: "",
        electrics: "",
      },
      manageInfo: "",
      reportInfo:'',
      operatorInfo: {
        safeCodeImg: "",
      },
      newoperatorInfo: {},
      complanumber: "",
      transactionsInfo: {},
      moneyInfo: {},
      insuredInfo: {},
      complainInfo: {},
      damagedInfo: {},
      labels: {},
      citys: ["北京", "上海", "天津", "重庆", "香港", "澳门"],
      strs: "",
      parentData: {
        cid: "",
      },
      titleStr: "",
      getOverDesdata: [],
      queryData: {},
      initcitys: {},
      shareholderInfodata:[{
        entName:'',
        conProp:'',
        conAm:''
      }],
      showReturn: false
    };
  },
  filters: {
    numFilter(value) {
      // 截取当前数据到小数点后两位
      let realVal = parseFloat(value).toFixed(2);
      return realVal;
    },
  },
  watch: {
    radio1: function (val) {
      this.getepidemicPreventionCumulative();
      // this.getTransactionsInfo();
    },
    radio2: function (val) {
      this.getMoneyInfo();
    },
    radio3: function (val) {
      this.getInsuredInfo();
    },
    radio4: function (val) {
      this.getComplainInfo();
    },
    radio5: function (val) {
      this.getTransactionsInfo();
    },
    tabPosition2: function (val) {



      this.getepidemicPreventionCumulative();
    },
  },
  mounted() {
    this.parentData = JSON.parse(localStorage.getItem("rowData"));
    console.log(this.queryData,'---222')
    this.queryData = JSON.parse(window.sessionStorage.getItem("initCitys"));
    if(!this.queryData){
      this.queryData = {
        provinceId:'',
        cityId:'',
        areaId:''
      }
    }
    console.log(this.queryData)
    this.initcitys.pid = this.queryData.provinceId || '';
    this.initcitys.cid = this.queryData.cityId || '';
    this.initcitys.did = this.queryData.areaId || '';

    // this.setLabels();
    // if (this.parentData.location.indexOf("杭州市") !== -1) {
    //   this.titleStr =
    //     `本报告由${this.strs}单用途预付消费卡协同监管服务平台出具，依据报告期内系统采集的信息数据生成。报告中的信息数据均由政府大数据管理机构、经营主体及第三方合作机构提供。${this.strs}单用途预付消费卡协同监管服务平台承诺在信息数据采集、汇总、分析、展示等全过程中保持客观、中立立场。 本报告仅向预付消费卡监管部门工作人员提供，须妥善保管。因保管不当造成信息泄露的，应承担相关行政或法律责任。`;
    // } else{
    //   this.titleStr =
    //     `本报告由${this.strs}单用途预付消费卡协同监管服务平台出具，依据报告期内系统获得的信息数据生成。报告中的信息数据均由政府大数据管理机构、经营主体及第三方合作机构提供。${this.strs}单用途预付消费卡协同监管服务平台承诺在信息数据采集、汇总、分析、展示等全过程中保持客观、中立立场。 本报告仅向预付消费卡监管部门工作人员提供，须妥善保管。因保管不当造成信息数据泄露的，应承担相关行政或法律责任。`;
    // }

    this.intData();
  },
  methods: {
    parseSearchArgs() {
      var url = window.location.search; //获取url中"?"符后的字串
      // if (window.location.hash.indexOf("?") !== -1) {
      //     window.sessionStorage.setItem("urlParams", '?' + url.split("?")[1])
      // }
      var rst = {};
      if (url.indexOf("?") != -1) {
        var str = url.split("?")[1];
        var parts = str.split("&");
        for (var i = 0; i < parts.length; i++) {
          rst[parts[i].split("=")[0]] = decodeURI(parts[i].split("=")[1]);
        }
      }
      return rst;
    },
    toDownload() {
      // let ids = [
      //   3857, 6744, 26, 82, 332, 358, 359, 421, 426, 440, 2047, 2048, 2054,
      //   2056, 2057, 2058, 2059, 2060, 2065, 2066,
      // ];
      // if (ids.indexOf(this.$route.query.riskBusId - 0) !== -1) {
      //   window.location.href = `https://reportapi.900sui.com/img/${this.parentData.busName}.pdf`;
      // } else {
      console.log(
          `${this.baseUrl}/api/intelligent/exportPdf?pid=${this.initcitys.pid}&cid=${this.initcitys.cid}&did=${this.initcitys.did}&riskBusId=${this.$route.query.riskBusId}`
      );
      window.location.href = `${this.baseUrl}/api/intelligent/exportPdf?pid=${this.initcitys.pid}&cid=${this.initcitys.cid}&did=${this.initcitys.did}&riskBusId=${this.$route.query.riskBusId}`;

      // }
    },
    goBack() {
      // console.log(window.sessionStorage.getItem('queryData'))

      this.$router.push({
        path: `/Intelligent`,
        query: this.queryData,
      });
    },

    setLabels() {
      this.labels = JSON.parse(window.sessionStorage.getItem("labels"));
      // console.log(this.labels)
      // if(!this.labels.pLabel && !this.labels.cLabel && !this.labels.dLabel){
      //   this.strs = this.parentData.location;
      // }
      if (this.labels.pLabel) {
        if (this.citys.indexOf(this.labels.pLabel) !== -1) {
          this.strs = `${this.labels.pLabel}市`;
          if (this.labels.dLabel) {
            this.strs += this.labels.dLabel;
            console.log("this.strs===", this.strs);
          }
        } else {
          this.strs = `${this.labels.pLabel}省`;
          if (this.labels.cLabel) {
            this.strs += this.labels.cLabel;
            if (this.labels.dLabel) {
              this.strs += this.labels.dLabel;
              this.strs = this.strs.split("省")[1];
            }
          }
        }
      }
      // console.log(this.citys.indexOf(this.labels.pLabel) !== -1)
    },
    intData() {
      let obj = this.parseSearchArgs();
      console.log(this.$route.query.riskBusId,'-----')
      if(window.sessionStorage.getItem("labels")) this.showReturn = true;
      if (this.$route.query.riskBusId || obj.riskBusId) {
        this.getBasicInfo();
        // this.getPublicInfo();
        this.getPublicOweInfo();
        // this.getManageInfo();
        this.getOperatorInfo();
        this.getTransactionsInfo();
        this.getMoneyInfo();
        this.getInsuredInfo();
        this.getComplainInfo();
        this.getDamagedInfo();
        this.getcontractStatistics();
        this.getconsumerStatistics();
        this.getepidemicPreventionCumulative();
        this.getSafeCodeColor();
        this.getOverDes();
        this.getshareholderInfo();
      }
    },
    getBasicInfo() {
      basicInfo({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        if (res.code == 500) {
          this.basicInfo;
        } else if (res.code == 0) {
          this.basicInfo = res.data;
        }
      });
    },
    getshareholderInfo() {
      shareholderInfo({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        if (res.code == 500) {
          this.shareholderInfodata;
        } else if (res.code == 0) {
          this.shareholderInfodata = res.data;
        }
        if(!this.shareholderInfodata.length){
          this.shareholderInfodata = [{
            entName:'',
            conProp:'',
            conAm:''
          }]
        }
      });
    },
    getPublicInfo() {
      publicInfo({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        if (res.code == 500) {
          this.publicInfo;
        } else if (res.code == 0) {
          // console.log(res.data, "公共信息");
          // this.publicInfo = res.data;
        }
      });
    },
    getPublicOweInfo() {
      publicOweInfo({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        // console.log(res, 12345);
        if (res.code == 500) {
          this.publicOweInfo;
        } else if (res.code == 0) {
          this.publicOweInfo = res.data;
        }
      });
    },
    getManageInfo() {
      manageInfo({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        if (res.code == 500) {
          this.contributor;
        } else if (res.code == 0) {
          this.contributor = res.data;
          console.log(res.data)
        }
        // this.manageInfo = res.data;
      });
    },
    getOperatorInfo() {
      operatorInfo({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        // console.log(res, "资金管理");
        this.operatorInfo = res.data;
      });
    },
    getSafeCodeColor() {
      getSafeCodeColor({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        this.newoperatorInfo = res.data;
      });
    },
    getOverDes() {
      getOverDes({
        riskBusId: this.$route.query.riskBusId,
        ...this.initcitys,
      }).then((res) => {
        // console.log(res, '风险提示语');
        this.publicInfo = res.data.ggxx || '暂无数据';
        this.getOverDesdata = res.data.riskWarning;
        this.manageInfo = res.data.jyqk || '暂无数据';
        this.reportInfo = res.data.bgsm || '暂无数据';
      });
    },
    getTransactionsInfo() {
      transactionsInfo({
        riskBusId: this.$route.query.riskBusId,
        type: this.radio5,
      }).then((res) => {
        this.transactionsInfo = res.data;
        this.newlineChartData = {
          xAxisData: res.data.x_axis,
          seriesData: [
            {
              name: "交易笔数",
              data: res.data.nums,
            },
            {
              name: "交易金额",
              data: res.data.trans,
            },
            {
              name: "兑付金额",
              data: res.data.cashs,
            },
            {
              name: "兑付比",
              data: res.data.ratios,
            },
          ],
        };
      });
    },
    getMoneyInfo() {
      moneyInfo({
        riskBusId: this.$route.query.riskBusId,
        type: this.radio2,
      }).then((res) => {
        this.moneyInfo = res.data;
        this.lineChartData2 = {
          xAxisData: res.data.x_axis,
          seriesData: [
            {
              name: "应存管发卡金额",
              data: res.data.trans,
            },
            {
              name: "存管资金",
              data: res.data.cgs,
            },
          ],
        };
      });
    },
    getInsuredInfo() {
      insuredInfo({
        riskBusId: this.$route.query.riskBusId,
        type: this.radio3,
      }).then((res) => {
        this.insuredInfo = res.data;
        // console.log(res.data,123456543)
        this.lineChartData3 = {
          xAxisData: res.data.x_axis,
          seriesData: [
            {
              name: "应保险发卡金额",
              data: res.data.moneys,
            },
            {
              name: "保额",
              data: res.data.bes,
            },
          ],
        };
      });
    },
    getComplainInfo() {
      complainInfo({
        riskBusId: this.$route.query.riskBusId,
        type: this.radio4,
      }).then((res) => {
        this.complainInfo = res.data;
        // console.log(res.data);
        this.lineChartData4 = {
          xAxisData: res.data.x_axis,
          seriesData: [
            {
              name: "投诉",
              data: res.data.complaints,
            },
          ],
        };
      });

      complainInfo({
        riskBusId: this.$route.query.riskBusId,
        type: 4,
      }).then((res) => {
        let number = null;
        res.data.complaints.map((v) => {
          number += v;
        });
        this.complanumber = number;
      });
    },
    getDamagedInfo() {
      damagedInfo({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        this.damagedInfo = res.data;
      });
    },

    getcontractStatistics() {
      contractStatistics({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        // console.log(res.data, "恰努月");
        this.forCodeData[0].seriesData[0].value = res.data.signNum;
        this.forCodeData[0].seriesData[1].value = res.data.unSignNum;
        this.forCodeData[1].seriesData[0].value = res.data.keepContractNum;
        this.forCodeData[1].seriesData[1].value = res.data.unKeepContractNum;

        this.forCodeData[0].seriesData[0].rote = res.data.signRate;
        this.forCodeData[0].seriesData[1].rote = res.data.unSignRate;
        this.forCodeData[1].seriesData[0].rote = res.data.keepContractRate;
        this.forCodeData[1].seriesData[1].rote = res.data.unKeepContractRate;
      });
    },
    getconsumerStatistics() {
      consumerStatistics({
        riskBusId: this.$route.query.riskBusId,
      }).then((res) => {
        this.forCodeDatatwo = [
          {
            seriesData: [
              {
                name: "儿童",
                value: res.data.childConsumersRate,
              },
              {
                name: "少年",
                value: res.data.juvenileConsumersRate,
              },
              {
                name: "青年",
                value: res.data.youthConsumersRate,
              },
              {
                name: "中年",
                value: res.data.middleConsumersRate,
              },
              {
                name: "老年",
                value: res.data.oldConsumersRate,
              },
            ],
            xfrq: res.data.consumersNum,
            xfrqcom: "人",
            Gender: [
              {
                name: "男",
                value: res.data.maleConsumersRate,
              },
              {
                name: "女",
                value: res.data.femaleConsumersRate,
              },
              {
                name: "其他",
                value: res.data.unkonwConsumersRate,
              },
            ],
            // man: parseFloat(res.data.maleConsumersRate),
            // woman: parseFloat(res.data.femaleConsumersRate),
            type: 1,
          },
          {
            seriesData: [
              {
                name: "儿童",
                value: res.data.childAmountRate,
              },
              {
                name: "少年",
                value: res.data.juvenileAmountRate,
              },
              {
                name: "青年",
                value: res.data.youthAmountRate,
              },
              {
                name: "中年",
                value: res.data.middleAmountRate,
              },
              {
                name: "老年",
                value: res.data.oldAmountRate,
              },
            ],
            xfrq: res.data.amountNum,
            xfrqcom: "元",
            Gender: [
              {
                name: "男",
                value: res.data.maleAmountRate,
              },
              {
                name: "女",
                value: res.data.femaleAmountRate,
              },
              {
                name: "其他",
                value: res.data.unkownAmountRate,
              },
            ],
            // man: parseFloat(res.data.maleAmountRate),
            // woman: parseFloat(res.data.femaleAmountRate),
            type: 2,
          },
        ];
      });
    },
    getepidemicPreventionCumulative() {
      epidemicPreventionCumulative({
        infoType: this.tabPosition2,
        riskBusId: this.$route.query.riskBusId,
        type: this.radio1,
      }).then((res) => {
        this.barChartData = {
          xAxisData: res.data.x_axis,
          seriesData: [
            {
              name:
                  this.tabPosition2 == 1
                      ? "正常门店数"
                      : this.tabPosition2 == 2
                      ? "正常从业人数"
                      : "正常消费人数",
              data:
                  this.tabPosition2 == 1
                      ? res.data.normalShopNum
                      : this.tabPosition2 == 2
                      ? res.data.normalEmployeeNum
                      : res.data.normalConsumerNum,
            },
            {
              name:
                  this.tabPosition2 == 1
                      ? "异常门店数"
                      : this.tabPosition2 == 2
                      ? "异常从业人数"
                      : "异常消费人数",
              data:
                  this.tabPosition2 == 1
                      ? res.data.abnormalShopNum
                      : this.tabPosition2 == 2
                      ? res.data.abnormalEmployeeNum
                      : res.data.abnormalConsumerNum,
            },
          ],
        };
      });
    },
  },
  components: {
    LineChart,
    BarChart,
    LineChartnew,
    Pieadvance,
    PieEpide,
  },
};
</script>

<style lang="scss"  >
.Intelligent {
  position: relative;
  .pdfIframe {
    position: fixed;
    left: 5vw;
    top: 5vw;
    width: 90vw;
    height: 90vh;
    z-index: 2000;
  }

  .goback {
    font-size: 16px;
  }
  .left-box {
    width: 220px;
    background: url("../assets/imgs/insight-bg.jpg") no-repeat;
    padding-top: 10px;
  }
  .pie-box {
    background: url("../assets/imgs/pie-bg3.png") no-repeat;
    background-size: 100% 100%;
    margin-bottom: 0;
    .echarts-box {
      position: relative;
      .echarts-tit {
        .txt {
          margin-left: 30px;
          line-height: 40px;
          i {
            font-weight: bold;
          }
        }
      }
    }
    .pie-chart-box {
      .txt {
        width: 50%;
        float: left;
        box-sizing: border-box;
        text-align: center;
        margin-top: 50px;
        span:nth-child(1) {
          display: block;
          font-size: 18px;
        }
        span:nth-child(2) {
          display: block;
          font-size: 30px;
          color: #5bd5f7;
          i {
            font-size: 18px;
            margin-left: 10px;
          }
        }
        &:nth-child(2) {
          border-left: 1px solid transparent;
          border-image: linear-gradient(
                  transparent,
                  transparent,
                  #17688d,
                  #17688d,
                  transparent,
                  transparent
          )
          0 30 30 30;
          span:nth-child(2) {
            color: #208dfd;
          }
        }
      }
      .pie-charts {
        float: left;
        width: 50%;
        height: 550px;
        box-sizing: border-box;
        background: url("../assets/imgs/pie-bg2.png") no-repeat center center;
        background-size: 55%;
        &:nth-child(3) {
          border-left: 1px solid transparent;
          border-image: linear-gradient(
                  #17688d,
                  #17688d,
                  transparent,
                  transparent
          )
          30 30;
        }
      }
    }
  }
  .detail-box {
    .detail-box-title {
      display: flex;
      justify-content: space-between;
    }
    .title {
      background: url("../assets/imgs/logo-line.png") no-repeat center bottom;
      padding-bottom: 25px;
      text-align: center;
      margin-top: 30px;
      margin-bottom: 20px;
      span {
        line-height: 40px;
        font-size: 36px;
        color: transparent;
        background: linear-gradient(to bottom, #13caff, #2285ff);
        -webkit-background-clip: text;
      }
    }
    .text-info {
      color: rgba(44, 195, 255, 0.7);
      text-indent: 2em;
      line-height: 28px;
      font-size: 14px;
    }
    .conbox {
      border: 1px solid #ddd;
      border-image: -webkit-linear-gradient(#17688d, #040d23) 20 20;
      border-image: -moz-linear-gradient(#17688d, #040d23) 20 20;
      border-image: -o-linear-gradient(#17688d, #040d23) 20 20;
      border-image: linear-gradient(#17688d, #040d23) 20 20;
      border-radius: 10px;
      margin: 30px 0 0;
      background: linear-gradient(to bottom, #2bcaff1a, #0000, #0000);
      .text-info {
        margin: 40px 30px 50px;
        b {
          font-weight: 400;
          display: inline;
        }

        & > p > span {
          color: #ccc !important;
        }
      }
      .sub-title {
        font-weight: bold;
        text-align: center;
        background: url("../assets/imgs/title-bg.png") no-repeat center bottom;
        padding: 30px 0 20px;
        i {
          display: inline-block;
          width: 26px;
          height: 26px;
          position: relative;
          top: 4px;
          right: 10px;
        }
        .icon-tit1 {
          background: url("../assets/imgs/icon-tit2.png") no-repeat;
        }
        .icon-tit2 {
          background: url("../assets/imgs/icon-tit1.png") no-repeat;
        }
        .icon-tit3 {
          background: url("../assets/imgs/icon-tit3.png") no-repeat;
        }
        span {
          font-size: 24px;
          color: #30b5ff;
        }
      }
      .sub-conbox {
        position: relative;
        margin: 30px 20px;
        .item-title {
          line-height: 40px;
          margin-bottom: 20px;
          background: linear-gradient(to right, #12344d, #0000, #0000, #0000);
          border-left: 2px solid #55d0ff;
          span {
            margin-left: 20px;
            background: linear-gradient(to bottom, #18c3ff, #248aff);
            -webkit-background-clip: text;
            color: transparent;
            font-style: normal;
            font-weight: bold;
            font-size: 18px;
          }
        }
        .table {
          width: 100%;
          tr {
            border: 1px solid rgba(98, 156, 173, 0.2);
          }
          th,
          td {
            height: 50px;
            line-height: 25px;
            font-size: 14px;
          }
          th {
            color: #acbcc1;
            width: 170px;
            text-align: right;
            padding: 0 20px 0 20px;
            background: rgba(96, 152, 234, 0.2);
            box-sizing: border-box;
            font-weight: 500;
          }
          td {
            color: #fff;
            text-align: left;
            padding-left: 20px;
            min-width: 100px;
          }
        }
        .code-table {
          th {
            width: 130px;
          }
          .code-img-box {
            text-align: center;
            padding-right: 20px;
            h5 {
              color: #bad6de;
              font-size: 16px;
              font-weight: bold;
              margin: 15px 0 20px;
            }
            .code-img {
              margin-bottom: 20px;
              img {
                width: 110px;
                height: 110px;
              }
              span {
                display: block;
                color: #40d17d;
                font-size: 14px;
              }

              .color1 {
                color: grey;
              }
              .color2 {
                color: red;
              }
              .color3 {
                color: yellow;
              }
              .color4 {
                color: green;
              }
              .color5 {
                color: black;
              }
            }
            p:last-child {
              line-height: 22px;
              font-size: 12px;
              color: #acbcc1;
            }
          }
        }
        .number-box {
          display: flex;
          justify-content: space-around;
          margin-bottom: 30px;
          .number-item {
            text-align: center;
            p {
              color: #fff;
              font-size: 16px;
              line-height: 30px;
              margin: 10px 0;
            }
            .box {
              position: relative;
              float: left;
              &.bg1 {
                width: 196px;
                height: 79px;
                background: url("../assets/imgs/number-bg.png") no-repeat;
              }
              &.bg2 {
                width: 274px;
                height: 79px;
                background: url("../assets/imgs/number2-bg.png") no-repeat;
              }
              i {
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: 0;
                &:nth-child(1) {
                  top: 0;
                  left: 0;
                  background: url("../assets/imgs/number-left-bg.png") no-repeat
                  left bottom;
                }
                &:nth-child(2) {
                  top: 0;
                  right: 0;
                  background: url("../assets/imgs/number-right-bg.png")
                  no-repeat right top;
                }
              }
              p {
                position: relative;
                z-index: 9;
                line-height: 79px;
                font-size: 28px;
                font-weight: bold;
                color: #5bd5f7;
                margin: 0;
              }
            }
            .right {
              position: relative;
              left: -21px;
              float: left;
              background: url("../assets/imgs/arrow-right.png") no-repeat left
              center;
              p {
                line-height: 24px;
                text-align: left;
                margin-left: 55px;
                color: #5bd5f7;
                font-size: 14px;
              }
            }
          }
        }

        .charts {
          width: 100%;
          height: 400px;
          margin-top: 10px;
        }
      }
    }
  }
  .tab-select-btn {
    position: absolute;
    top: 6px;
    right: 0;
    &.right-size {
      right: 120px;
    }
  }
  .idiot-one {
    display: flex;
    justify-content: space-around;
    height: 300px;
    margin: 0 30px 30px 30px;
    // border: 1px solid #17688d;
    // background: linear-gradient(
    //   to bottom,
    //   rgba(43, 202, 255, 0.1),
    //   #0000,
    //   rgba(43, 202, 255, 0.1)
    // );

    .box {
      width: 49.8%;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .box-left {
        width: 100%;
        position: relative;
        .box-leftchild {
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 18px;
          color: white;
          & > div {
            font-size: 26px;
            color: #208dfd;
            height: 35px;
            line-height: 30px;
            margin: 0 20px;
          }
        }
        .box-left-childtop {
          font-size: 16px;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: left;
          .box-left-childtopval {
            font-size: 24px;
            color: #208dfd;
            text-align: left;
          }
        }
        .box-left-childbottom {
          width: 200px;
          height: 66px;
          margin-left: 68px;
          flex-wrap: wrap;
          position: absolute;
          right: -70px;
          top: 90px;
          .box-left-childbottomval {
            width: 50%;
            height: 22px;
            font-size: 12px;
            color: white;
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-top: 5px;
            .box-left-childbottomvalicon {
              width: 8px;
              height: 8px;
              border-radius: 8px;
            }
            .box-left-childbottomvalpercentage {
              font-size: 14px;
            }
          }
        }
      }

      .box-right {
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      &:nth-child(2) {
        .box-header span {
          color: #18bbff;
        }
      }
    }

    .box:nth-child(1) {
      border-right: 1px solid transparent;
      border-image: linear-gradient(transparent, #17688d, transparent) 0 30;
    }
  }
  .getOverDesdatabox {
    width: 100%;
    .getOverDesdatachild {
      width: 100%;
      height: 60px;
      font-size: 18px;
      color: rgba(44, 195, 255, 0.7);
      text-indent: 40px;
      line-height: 60px;
    }
  }
}
</style>
