<template>
  <div :style="{ height: height, width: width, }" :class="`bg ${this.type? 'pos' : ''}`" ref="chart"></div>
</template>
<script>
import { debounce } from "lodash";
const echarts = require("echarts");
export default {
  components: {},
  props: {
    chartData: {
      type: Object,
      default: () => {
        return {
          Gender:[],
          seriesData: [
            {
              name: "儿童",
              value: 1,
            },
            {
              name: "少年",
              value: 1,
            },
            {
              name: "青年",
              value: 1,
            },
            {
              name: "中年",
              value: 1,
            },
            {
              name: "老年",
              value: 1,
            },
          ],
        };
      },
    },
    width: {
      type: String,
      default: "120%",
    },
    height: {
      type: String,
      default: "120%",
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      colors: ["#125fe0", "#208dfd", "#18bbff", "#00ff5a", "#c6ff00"], //['#00ff5a', '#26fff7']
      newcolor:['#26fff7',"#208dfd"]
    };
  },
  computed: {},
  watch: {
    chartData: {
      handler(val, olVal) {
        //需要在这从新执行一次
        if (val) {
          this.initCharts();
        }
      },
      deep: true,
    },
  },
  methods: {
    initCharts() {
      let _this = this;
      this.chart = echarts.init(this.$refs.chart);
      this.chart.setOption({
        title: {
          name: "1234",
        },
        series: [
          {
            type: "pie",
            center: ["50%", "50%"],
            radius: ["45%", "60%"],
            hoverAnimation: false,
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            itemStyle: {
              normal: {
                borderColor: "#216ba1",
                borderWidth: 2,
                color: function (params) {
                  return _this.colors[params.dataIndex];
                },
              },
            },
            data: _this.chartData.seriesData,
          },
          {
            type: "pie",
            center: ["50%", "50%"],
            radius: ["35%", "40%"],
            hoverAnimation: false,
            avoidLabelOverlap: false,
            label: {
              normal: {
                position: "center",
                formatter: (params) => {
                  // console.log(params)
                  return (
                    "{top| 男 " +
                    _this.chartData.Gender[0].value +
                    "%}\n" +
                    "{bottom| 女 " +
                    _this.chartData.Gender[1].value +
                    "%}\n" +
                    "{bottom| 其他 " +
                      (_this.chartData.Gender[2].value || '0.00') +
                    "%}"
                  );
                },
                rich: {
                  top: {
                    fontSize: 12,
                    color: "#ffffff",
                    fontFamily: "PingFangSC",
                    lineHeight:16
                  },
                  bottom: {
                    fontSize: 12,
                    color: "#ffffff",
                    // padding: [0, 0, 12, 0],
                    fontFamily: "PingFangSC",
                    lineHeight:16
                  },
                },
              }
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            itemStyle: {
              normal: {
                borderColor: "#216ba1",
                borderWidth: 2,
                color: function (params) {
                  return _this.newcolor[params.dataIndex];
                },
              },
            },
            data: _this.chartData.Gender,
          }
        ],
      });
    },
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
  mounted() {
    this.initCharts();
    this.resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.resizeHandler);

    // let _this = this;
    // this.chart.on("click", function (params) {
    //   if (params.data.type == "supervise") {
    //     _this.$emit("getClickData", params.name);
    //   } else if (params.data.type == "place") {
    //     _this.$emit("getClickData", params.name);
    //   }
    // });
  },
};
</script>
<style lang="scss" scoped>
.pos{
  position: absolute;
  top: -10%;
  left: -10%;
}
</style>
