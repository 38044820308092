<template>
  <div :style="{ height: height, width: width }" class="bg" ref="chart"></div>
</template>
<script>
import { debounce } from "lodash";
const echarts = require("echarts");
export default {
  components: {},
  props: {
    chartData: {
      type: Object,
      default: {
        seriesData: [
          {
            name: "儿童",
            value: 37,
            valuecom: "人",
          },
          {
            name: "少年",
            value: 50,
            valuecom: "人",
          },
        ],
      },
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    }
  },
  data() {
    return {
      colors: ["#1577e5","#00ffff"], //['#00ff5a', '#26fff7']
    };
  },
  computed: {},
  watch: {
    chartData: {
      handler(val, olVal) {
        //需要在这从新执行一次
        if (val) {
          this.initCharts();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.initCharts();
    this.resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.resizeHandler);

    let _this = this;
    this.chart.on("click", function (params) {
      if (params.data.type == "supervise") {
        _this.$emit("getClickData", params.name);
      } else if (params.data.type == "place") {
        _this.$emit("getClickData", params.name);
      }
    });
  },
  methods: {
    initCharts() {
      let _this = this;
      this.chart = echarts.init(this.$refs.chart);

      this.chart.setOption({
        series: [
          {
            type: "pie",
            center: ["50%", "50%"],
            radius: ["30%", "65%"],
            label: {
              formatter: "{b}: {d}%",
              color: "white",
            },
            labelLine: {
              normal: {
                show: true,
                length1: 8,
                length2: 0,
              },
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  return _this.colors[params.dataIndex];
                },
              },
            },
            data: _this.chartData.seriesData,
          },
        ],
      });
    },
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener("resize", this.resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
};
</script>